import { Box, Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { theme } from "app/theme";
import { NavLink } from "features/navigation/NavLink";
import { TypedQuestions } from "features/qa/TypedQuestions";

export const QuestionBox = () => {
    return (
        <NavLink
            to={routes.askATeacher()}
            disableLinkStyles
            onClick={() => analyticsTrack("dashboard.question.box.clicked")}
        >
            <Box
                sx={{
                    display: "flex",
                    mb: { xs: 2, md: 4 },
                    borderRadius: `${theme.borderRadius.default}px`,
                    "&:hover": { boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.14)" },
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.grey300,
                        width: { xs: "100%", md: "30%" },
                        borderTopLeftRadius: `${theme.borderRadius.default}px`,
                        borderBottomLeftRadius: { xs: 0, md: `${theme.borderRadius.default}px` },
                        borderTopRightRadius: { xs: `${theme.borderRadius.default}px`, md: 0 },
                        height: { xs: 100, md: 150 },
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            overflow: "hidden",
                            position: "relative",
                            paddingTop: "56.25%",
                        }}
                    >
                        <iframe
                            src="https://player.vimeo.com/video/1040398750?autoplay=1&loop=1&background=1&muted=1&player_id=0&app_id=58479"
                            allow="autoplay"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "none",
                            }}
                            title="waves"
                        ></iframe>
                    </div>
                </Box>
                <Box
                    sx={{
                        backgroundColor: theme.palette.grey100,
                        p: { xs: 2, md: 3 },
                        flexGrow: 1,
                        width: { xs: "100%", md: "70%" },
                        borderTopRightRadius: { xs: 0, md: `${theme.borderRadius.default}px` },
                        borderBottomRightRadius: `${theme.borderRadius.default}px`,
                        borderBottomLeftRadius: { xs: `${theme.borderRadius.default}px`, md: 0 },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography variant="body3">
                            Ask a question about your practice or life and a teacher will answer
                            you.
                        </Typography>
                        <Typography variant="h4">
                            <TypedQuestions />
                        </Typography>
                    </Box>
                    <span className="material-symbols-rounded">chevron_right</span>
                </Box>
            </Box>
        </NavLink>
    );
};
