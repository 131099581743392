import { useLazyQuery, useQuery } from "@apollo/client";
import {
    MembershipStatus,
    MINDFUL_RELATING_COURSE_ID,
    MINDFULNESS_MENTOR_TRAINING_COURSE_ID,
} from "@app/shared/constants";
import { Membership, ProductFeature, Sangha, SanghaType } from "@app/shared/types";
import { isFeatureEnabled, SiteFeature } from "app/featureFlags";
import { GRAPHQL_QUERY_MEMBERSHIPS, GRAPHQL_QUERY_MY_SANGHAS } from "app/queries";
import {
    selectCanAccessMemberZone,
    selectIsLoggedIn,
    selectIsTeacher,
    selectUser,
} from "features/auth/auth";
import { NOTION_FAQ_PAGE } from "features/pages/FAQPage";
import { useIsUserEnrolledInCourses } from "hooks/useIsUserEnrolledInCourses";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { routes } from "../../app/routes";

const TEACHER_PORTAL_LINK =
    "https://www.notion.so/banyantogether/Cloud-Sangha-Teacher-Portal-55811247c7ee4698a198515e8a579f90";

export enum LinkTag {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    ACCOUNT_LINKS = "ACCOUNT_LINKS",
}

export interface MenuLinkInfo {
    name: string;
    to: string;
    external?: boolean;
    hashLink?: boolean;
    testId?: string;
    tags: LinkTag[];
    isAnchorLink?: boolean;
    indicatorKey?: string;
    subNavLinks?: MenuLinkInfo[];
}

// Location Identifiers

const isMemberZoneRoot = (path: string): boolean => {
    const match = matchPath(path, {
        path: routes.memberZone(),
        exact: true,
    });
    return Boolean(match);
};

// Links

const aboutUsLink = (): MenuLinkInfo => ({
    name: "About us",
    to: routes.about(),
    tags: [],
});

const recordingsLink = (): MenuLinkInfo => ({
    name: "Recordings",
    to: "https://banyantogether.notion.site/d6b24ef7ceac4579959a172d905ab264?v=28bf310df5c542a5b951d989c2644072",
    external: true,
    tags: [],
});

const accountLink = (): MenuLinkInfo => ({
    name: "Profile",
    to: routes.memberMembership(),
    tags: [LinkTag.ACCOUNT_LINKS],
});

const contactUsLink = (): MenuLinkInfo => ({
    name: "Contact us",
    to: routes.contactUs(),
    tags: [],
});

const faqLink = (): MenuLinkInfo => {
    return {
        name: "FAQ",
        to: NOTION_FAQ_PAGE,
        external: true,
        tags: [],
    };
};

const memberHomeLink = (): MenuLinkInfo => ({
    name: "Dashboard",
    to: routes.memberHomePage(),
    testId: "memberHomeNavLink",
    tags: [],
});

const logInOutButton = (isLogout: boolean): MenuLinkInfo => ({
    name: isLogout ? "Log out" : "Login",
    to: routes.memberZone(),
    testId: isLogout ? "logoutNavLink" : "loginNavLink",
    tags: isLogout ? [LinkTag.LOGOUT, LinkTag.ACCOUNT_LINKS] : [LinkTag.LOGIN],
});

const memberSessionsLink = (): MenuLinkInfo => ({
    name: "Sessions",
    to: routes.memberSessions(),
    testId: "sessionsNavLink",
    tags: [],
});

const memberContactSupportLink = (): MenuLinkInfo => ({
    name: "Support",
    to: routes.contactUs(),
    tags: [LinkTag.ACCOUNT_LINKS],
});

const memberHelpLink = (): MenuLinkInfo => ({
    ...faqLink(),
    tags: [LinkTag.ACCOUNT_LINKS],
});

const memberGuidelinesLink = (): MenuLinkInfo => ({
    name: "Guidelines",
    to: routes.communityGuidelines(),
    tags: [],
});

const memberTeachersPage = (): MenuLinkInfo => ({
    name: "Teachers",
    testId: "teachersPageNavLink",
    to: routes.memberTeachersPage(),
    tags: [],
});

const conflictResolutionLink = (): MenuLinkInfo => ({
    name: "Conflict resolution",
    to: routes.conflictResolution(),
    tags: [],
});

const resourcesLink = (): MenuLinkInfo => {
    return {
        name: "Resources",
        to: "#",
        testId: "resourcesNavLink",
        tags: [],
        subNavLinks: [
            memberTeachersPage(),
            MRCLink(),
            MMTLink(),
            recordingsLink(),
            memberGuidelinesLink(),
            conflictResolutionLink(),
            memberHelpLink(),
            memberContactSupportLink(),
        ],
    };
};

const programsLink = (): MenuLinkInfo => {
    return {
        name: "Programs",
        to: "#",
        testId: "programsNavLink",
        tags: [],
        subNavLinks: [MRCLink(), MMTLink()],
    };
};

const knowMoreAboutBanyanLink = (): MenuLinkInfo => ({
    name: "Know More About Banyan",
    to: "https://banyantogether.com",
    external: true,
    tags: [],
});

const courseResourcesLink = (): MenuLinkInfo => {
    return {
        name: "Resources",
        to: "#",
        tags: [],
        testId: "courseResourcesNavLink",
        subNavLinks: [knowMoreAboutBanyanLink(), contactUsLink(), memberGuidelinesLink()],
    };
};

const teacherPortalLink = (): MenuLinkInfo => ({
    name: "Teacher Portal",
    to: TEACHER_PORTAL_LINK,
    external: true,
    tags: [LinkTag.ACCOUNT_LINKS],
});

const teacherSubstituteRequestsPage = (): MenuLinkInfo => ({
    name: "Substitute Sessions",
    to: routes.teacherSubstituteRequests(),
    testId: "substituteRequestsNavLink",
    tags: [LinkTag.ACCOUNT_LINKS],
});

const publicTeachersPageLink = (): MenuLinkInfo => ({
    name: "Teachers",
    to: routes.publicTeachersPage(),
    tags: [],
});

const webflowTeachersLandingPageLink = (): MenuLinkInfo => ({
    name: "Teachers",
    to: routes.publicTeachersLandingPage(),
    tags: [],
});

const loginLink = (): MenuLinkInfo => ({
    name: "Login",
    to: routes.memberZone(),
    testId: "loginNavLink",
    tags: [],
});

const MRCLink = (): MenuLinkInfo => ({
    name: "Intro to Mindful Relating",
    to: routes.courseEmbeddedLandingPage(MINDFUL_RELATING_COURSE_ID),
    testId: "mindfulRelatingCourseNavLink",
    tags: [],
});

const MMTLink = (): MenuLinkInfo => ({
    name: "Mindfulness Mentor Training",
    to: routes.courseEmbeddedLandingPage(MINDFULNESS_MENTOR_TRAINING_COURSE_ID),
    testId: "mindfulnessMentorTrainingCourseNavLink",
    tags: [],
});

const coursesLink = (): MenuLinkInfo => ({
    name: "Courses",
    to: routes.coursesDashboard(),
    testId: "coursesNavLink",
    tags: [],
});

const coursesPublicLink = (): MenuLinkInfo => ({
    name: "Courses",
    to: routes.coursesPublicDashboard(),
    testId: "coursesNavLink",
    tags: [],
});

const loungeLink = (): MenuLinkInfo => ({
    name: "Lounge",
    to: routes.lounge(),
    tags: [],
});

const retreatLink = (): MenuLinkInfo => ({
    name: "Retreat",
    external: true,
    to: routes.retreats(),
    tags: [],
});

const getSanghaLink = (sangha: Sangha) => ({
    name: sangha.name,
    to: routes.memberSanghaDetails(sangha.id),
    tags: [],
    testId: "mentorshipSubNavLink",
});

const getSanghaLinks = (sanghas: Sangha[], type: SanghaType) =>
    sanghas.filter((x) => x.type === type).map(getSanghaLink);

const sanghaLink = (sanghas: Sangha[], isImmersion: boolean): MenuLinkInfo => {
    return {
        name: "Mentorship",
        to: routes.memberSanghas(),
        testId: "mySanghaNavLink",
        tags: [],
        subNavLinks: getSanghaLinks(sanghas, SanghaType.MentorshipGroup),
        indicatorKey:
            isImmersion || !isFeatureEnabled(SiteFeature.Indicator)
                ? undefined
                : "mentorshipGroupIndicator",
    };
};

const publicMentorshipGroupsLink = (): MenuLinkInfo => ({
    name: "Mentorship",
    to: routes.publicMentorshipGroups(),
    tags: [],
});

/**
 * It returns an array of objects that describe the links to be displayed in the navigation bar
 * @param {boolean} [isFullPageMenu] - used for the switch group option in the navbar.
 * @returns An array of MenuLinkInfo objects.
 */
export const useNavigationLinks = (isFullPageMenu?: boolean): MenuLinkInfo[] => {
    const location = useLocation();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isTeacher = useSelector(selectIsTeacher);
    const canAccessMemberZone = useSelector(selectCanAccessMemberZone);
    const user = useSelector(selectUser);
    const isImmersion = user?.features?.includes(ProductFeature.DEDICATED_SANGHA) ?? false;

    const isEnrolledInCourses = useIsUserEnrolledInCourses();

    const {
        data: membershipData,
        loading: membershipsAreLoading,
        error: membershipsError,
    } = useQuery(GRAPHQL_QUERY_MEMBERSHIPS);

    const memberships = (membershipData?.myMemberships || []) as Membership[];
    const activeMemberships = memberships.filter((m) => MembershipStatus.isActive(m.status));
    const membership = activeMemberships[0];

    const logInOutButtonObj = logInOutButton(isLoggedIn);

    const initialLinks: MenuLinkInfo[] = [];

    const publicLinks: MenuLinkInfo[] = [
        webflowTeachersLandingPageLink(),
        aboutUsLink(),
        coursesPublicLink(),
        publicMentorshipGroupsLink(),
        // retreatLink(),
        isLoggedIn ? logInOutButtonObj : loginLink(),
    ];

    const [loadSanghas, sanghaQueryStatus] = useLazyQuery(GRAPHQL_QUERY_MY_SANGHAS, {
        fetchPolicy: "network-only",
        variables: { includePending: true },
    });
    const sanghas = (sanghaQueryStatus.data?.mySanghas || []) as Sangha[];
    useEffect(() => {
        if (isLoggedIn && canAccessMemberZone) {
            loadSanghas();
        }
    }, [isLoggedIn, canAccessMemberZone, user?.id]);

    if (isLoggedIn && canAccessMemberZone) {
        // For pending/cancelled membership notices, we just want to show the logout option
        if (isMemberZoneRoot(location.pathname)) {
            return [logInOutButtonObj];
        }

        initialLinks.push(
            memberHomeLink(),
            memberSessionsLink(),
            sanghaLink(sanghas, isImmersion),
            loungeLink(),
            resourcesLink(),
        );

        if (isTeacher) {
            return [
                ...initialLinks,
                accountLink(),
                teacherPortalLink(),
                teacherSubstituteRequestsPage(),
                logInOutButtonObj,
            ];
        } else {
            return [...initialLinks, accountLink(), logInOutButtonObj];
        }
    }

    // Non-member course participant menu links
    if (isLoggedIn && !canAccessMemberZone && isEnrolledInCourses) {
        initialLinks.push(coursesLink(), courseResourcesLink());
        return [...initialLinks, logInOutButtonObj];
    }

    // default visitor links
    if (isFullPageMenu) {
        return [...publicLinks];
    }

    return [...publicLinks];
};
