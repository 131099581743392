import { Avatar, Container, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { externalImageSrc } from "app/images";
import classNames from "classnames";
import { selectUserProfile } from "features/auth/auth";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useDispatch, useSelector } from "react-redux";
import { MenuLink } from "./MenuLink";
import { closeMenu, selectIsMenuOpen } from "./navigationSlice";
import { LinkTag, MenuLinkInfo, useNavigationLinks } from "./useNavigationLinks";
import { LinkButton } from "./LinkButton";
import { routes } from "app/routes";
import analytics from "analytics";
import { analyticsTrack } from "app/analytics/track";

const useStyles = makeStyles((theme) => ({
    fullPageMenu: {
        "& .MuiDialog-paper": {
            backgroundColor: theme.palette.primaryBanyan,
        },
        zIndex: theme.zIndex.modal + 1,
    },
    menuItemList: {
        listStyleType: "none",
        paddingInlineStart: 0,
        "& .MuiTypography-root.MuiLink-root": {
            color: theme.palette.neutralWhite,
            opacity: 0.6,

            "&:hover": {
                opacity: 1,
            },
        },
        "& li": {
            paddingLeft: theme.spacing(1),
        },
    },
    menuContainer: {
        padding: theme.spacing(2, 6),
        paddingBottom: theme.spacing(6),
        height: "100%",
    },

    closeButton: {
        position: "fixed",
        top: theme.spacing(2),
        right: theme.spacing(4),
        color: theme.palette.neutralWhite,
        zIndex: 1,
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(30),
    },
    accountMenu: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        position: "relative",
        marginBottom: theme.spacing(2),
    },
    avatar: {
        borderRadius: theme.borderRadius.circular,
        width: 26,
        height: 26,
        border: `2px solid ${theme.palette.accentGold}`,
        position: "absolute",
        top: 5,

        [theme.breakpoints.up("sm")]: {
            width: 28,
            height: 28,
            top: 19,
        },
    },
    profileLink: {
        paddingLeft: 33,
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 35,
        },
    },
    divider: {
        opacity: 0.3,
    },
}));

export const FullPageMenu = (props: any) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const menuIsOpen = useSelector(selectIsMenuOpen);
    const hamburgerMenuVisible = useBreakpointQuery("lg");
    const userProfile = useSelector(selectUserProfile);

    const links = useNavigationLinks(true);

    const topLinks = links.filter(
        (link) => link.name !== "Resources" && !link.tags.includes(LinkTag.ACCOUNT_LINKS),
    );
    const resourcesLink = links.filter((link) => link.name === "Resources");
    const accountMenuLinks = links.filter((link) => link.tags.includes(LinkTag.ACCOUNT_LINKS));

    const renderLink = (link: MenuLinkInfo) => {
        return (
            <li key={link.name}>
                <Typography variant="h2">
                    <MenuLink
                        link={link}
                        isFullPageMenu={true}
                        onClick={() => dispatch(closeMenu())}
                        onClickSubNav={() => dispatch(closeMenu())}
                    />
                </Typography>
            </li>
        );
    };

    const renderAccountMenu = (accountLinks: MenuLinkInfo[]) => {
        const profileLink: MenuLinkInfo = {
            name: "Profile",
            to: "#",
            tags: [LinkTag.ACCOUNT_LINKS],
            subNavLinks: accountLinks,
        };
        return (
            <li className={classes.accountMenu}>
                <Avatar
                    className={classes.avatar}
                    alt={userProfile?.fullName}
                    src={
                        userProfile?.picture
                            ? externalImageSrc(userProfile?.picture, 55)
                            : undefined
                    }
                />
                <Typography variant="h2" style={{ marginBottom: 0 }}>
                    <MenuLink
                        link={profileLink}
                        isFullPageMenu={true}
                        onClick={() => dispatch(closeMenu())}
                        onClickSubNav={() => dispatch(closeMenu())}
                        className={classes.profileLink}
                    />
                </Typography>
            </li>
        );
    };

    return (
        <Dialog
            fullScreen
            open={hamburgerMenuVisible && menuIsOpen}
            onClose={() => dispatch(closeMenu())}
            className={classes.fullPageMenu}
        >
            <Container maxWidth="lg" className={classes.menuContainer}>
                <Tooltip title="Close menu">
                    <IconButton
                        className={classes.closeButton}
                        aria-label="close"
                        onClick={() => dispatch(closeMenu())}
                        data-testid="closeMobileMenu"
                        size="medium"
                    >
                        <span className={classNames("material-symbols-rounded", classes.closeIcon)}>
                            close
                        </span>
                    </IconButton>
                </Tooltip>
                <ul className={classes.menuItemList}>{topLinks.map(renderLink)}</ul>

                {!!resourcesLink || accountMenuLinks.length > 0 ? (
                    <hr className={classes.divider} />
                ) : null}

                <ul className={classes.menuItemList}>
                    {resourcesLink.map(renderLink)}
                    {accountMenuLinks.length > 0 && renderAccountMenu(accountMenuLinks)}
                </ul>
                {userProfile && (
                    <LinkButton
                        variant="primary"
                        sx={{ px: 2 }}
                        to={routes.askATeacher()}
                        onClick={() => {
                            analyticsTrack("ask.teacher.button.clicked");
                            dispatch(closeMenu());
                        }}
                    >
                        Ask a teacher
                    </LinkButton>
                )}
            </Container>
        </Dialog>
    );
};
