import { UserProfile } from "@app/shared/types";
import { Avatar, Box, Button, CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserProfile } from "../../auth/auth";
import { saveProfilePicture } from "./memberProfileSlice";
import Webcam from "react-webcam";
import { theme } from "app/theme";
import { CSDialog } from "components/CSDialog";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 300,
        height: 200,
        borderRadius: theme.borderRadius.default,
        marginRight: theme.spacing(4),

        [theme.breakpoints.down("sm")]: {
            width: 150,
            height: 100,
        },
    },

    avatarLoading: {
        width: "100%",
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    profilePicImage: {
        paddingRight: theme.spacing(4),
    },
    photoContainer: {
        display: "flex",
    },
    photoButtonContainer: {
        display: "flex",
        gap: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
}));

export const ProfilePicture = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [photoDialogOpen, setPhotoDialogOpen] = useState(false);

    const userProfile = useSelector(selectUserProfile) as UserProfile;

    const [savingPicture, setSavingPicture] = useState(false);

    const [webCamEnabled, setWebCamEnabled] = useState(false);
    const [tookWebcamPhoto, setTookWebcamPhoto] = useState(false);

    const [webCamImage, setWebCamImage] = useState("");
    const [existingPicture, setExistingPicture] = useState(userProfile?.picture);

    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "user",
    };

    const WebcamCapture = () => (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Webcam
                audio={false}
                height={200}
                minScreenshotHeight={200}
                minScreenshotWidth={300}
                screenshotFormat="image/jpeg"
                width={300}
                videoConstraints={videoConstraints}
                mirrored={true}
                forceScreenshotSourceSize
            >
                {({ getScreenshot }) => (
                    <Button
                        variant="primary"
                        data-testid="startButton"
                        onClick={() => {
                            const imageSrc = getScreenshot();
                            if (imageSrc) {
                                setWebCamImage(imageSrc);
                                setWebCamEnabled(false);
                                setTookWebcamPhoto(true);
                                uploadPicture(undefined, imageSrc);
                                setPhotoDialogOpen(false);
                            }
                        }}
                        style={{
                            display: "block",
                        }}
                        size="small"
                    >
                        <span
                            className="material-symbols-rounded"
                            style={{
                                marginRight: theme.spacing(1),
                                fontSize: theme.typography.pxToRem(22),
                            }}
                        >
                            add_a_photo
                        </span>
                        Take photo
                    </Button>
                )}
            </Webcam>
        </Box>
    );

    const onPictureUpload = async (ev: React.ChangeEvent<any>) => {
        setWebCamEnabled(false);
        if (ev.target.validity.valid) {
            uploadPicture(ev.target.files[0]);
        }
        // if we just uploaded a photo, we want to keep track to know it's not a webcam photo
        setTookWebcamPhoto(false);
        setPhotoDialogOpen(false);
    };

    const uploadPicture = async (file?: any, data?: string) => {
        setSavingPicture(true);
        let result = await dispatch(
            saveProfilePicture({
                userProfile: userProfile,
                file: file,
                data: data,
            }),
        );
        const { payload } = result as any;
        setExistingPicture(payload);
        setSavingPicture(false);
    };

    const renderWebcamButton = () => {
        return (
            <Button
                variant="primary"
                onClick={() => {
                    setWebCamEnabled(true);
                }}
                size="small"
            >
                <span
                    className="material-symbols-rounded"
                    style={{
                        marginRight: theme.spacing(1),
                        fontSize: theme.typography.pxToRem(22),
                    }}
                >
                    add_a_photo
                </span>
                Take photo
            </Button>
        );
    };

    const renderUploadButton = () => {
        return (
            <>
                <input
                    type="file"
                    id="avatarUpload"
                    accept="image/png, image/jpeg, image/jpg"
                    data-testid="profileFileUpload"
                    hidden
                    onChange={onPictureUpload}
                />
                <label htmlFor="avatarUpload">
                    <Button variant="primary" component="span" size="small">
                        <span
                            className="material-symbols-rounded"
                            style={{
                                marginRight: theme.spacing(1),
                                fontSize: theme.typography.pxToRem(22),
                            }}
                        >
                            upload
                        </span>
                        Upload
                    </Button>
                </label>
            </>
        );
    };

    const renderPicture = () => {
        if (tookWebcamPhoto) {
            return <Avatar className={classes.avatar} src={webCamImage} />;
        } else if (existingPicture) {
            return (
                <Avatar
                    className={classes.avatar}
                    src={existingPicture}
                    data-testid="uploadedPicture"
                />
            );
        } else {
            return <Avatar className={classes.avatar} />;
        }
    };

    const renderRemoveButton = () => {
        return (
            <Button
                variant="secondary"
                onClick={() => {
                    uploadPicture();
                    setExistingPicture("");
                    setWebCamImage("");
                    setPhotoDialogOpen(false);
                }}
                size="small"
            >
                <span
                    className="material-symbols-rounded"
                    style={{
                        marginRight: theme.spacing(1),
                        fontSize: theme.typography.pxToRem(22),
                    }}
                >
                    delete
                </span>
                Remove
            </Button>
        );
    };

    return (
        <div>
            {savingPicture ? (
                <div className={classes.avatarLoading}>
                    <CircularProgress />
                </div>
            ) : (
                <Box className={classes.photoContainer}>
                    {renderPicture()}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            variant="secondary"
                            size="small"
                            onClick={() => setPhotoDialogOpen(true)}
                        >
                            Change
                        </Button>
                    </Box>
                </Box>
            )}
            <CSDialog
                open={photoDialogOpen}
                onClose={() => {
                    setPhotoDialogOpen(false);
                    setWebCamEnabled(false);
                }}
                style={{ maxWidth: 800, margin: "0 auto" }}
            >
                <Typography variant="h2">Change your profile picture</Typography>
                {webCamEnabled ? (
                    <WebcamCapture />
                ) : (
                    <Box className={classes.photoButtonContainer}>
                        {renderWebcamButton()}
                        {renderUploadButton()}
                        {renderRemoveButton()}
                    </Box>
                )}
            </CSDialog>
        </div>
    );
};

export default ProfilePicture;
