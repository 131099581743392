import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_MUTATION_PAY_FOR_ONE_TIME_DONATION } from "app/queries";
import { getFullUrl, routes } from "app/routes";
import { CSTextField } from "components/CSTextField";
import { displayPrice } from "components/displayPrice";
import { OptionBox } from "components/OptionBox";
import PageWrapper from "components/PageWrapper";
import LoadingPage from "features/pages/LoadingPage";
import { useStripeCheckout } from "hooks/useStripeCheckout";
import giveIcon from "images/icons/give2.svg";
import { useState } from "react";

export const DonationSection = (props: {
    setLoading: (value: boolean) => any;
    redirectUrl: string;
    analyticsPrefix?: string;
}) => {
    const amounts = [10, 25, 50, 100, 250, 500];

    const [showCustomAmount, setShowCustomAmount] = useState(false);
    const [donationAmount, setDonationAmount] = useState<number | undefined>(undefined);

    const redirectToStripe = useStripeCheckout(GRAPHQL_MUTATION_PAY_FOR_ONE_TIME_DONATION);

    const onSubmit = (amount: number) => {
        props.setLoading(true);
        const eventName = props.analyticsPrefix
            ? `${props.analyticsPrefix}.donation.submit`
            : `donation.submit`;
        analyticsTrack(eventName, { amount });
        redirectToStripe(
            {
                variables: {
                    amountInDollars: amount,
                },
            },
            getFullUrl(props.redirectUrl),
        );
    };

    const renderCustomDonationSection = () => (
        <>
            <Grid container>
                <Grid>
                    <CSTextField
                        type="number"
                        label="Your choice"
                        autoFocus
                        value={donationAmount}
                        data-testid={"customDonationAmount"}
                        onChange={(value) => setDonationAmount(parseInt(value, 0))}
                    />
                </Grid>
                <Grid>
                    <Button
                        variant="secondary"
                        sx={{ ml: 2 }}
                        data-testid="submitButton"
                        onClick={() => {
                            if (donationAmount) {
                                onSubmit(donationAmount);
                            }
                        }}
                    >
                        Donate
                    </Button>
                </Grid>
            </Grid>
        </>
    );
    return (
        <>
            <Typography variant="h3">How much would you like to donate?</Typography>

            {amounts.map((amount) => (
                <OptionBox key={amount} onClick={() => onSubmit(amount)}>
                    {displayPrice(amount)}
                </OptionBox>
            ))}

            {showCustomAmount ? (
                renderCustomDonationSection()
            ) : (
                <OptionBox
                    data-testid="customDonationOption"
                    onClick={() => setShowCustomAmount(true)}
                >
                    Your choice
                </OptionBox>
            )}
        </>
    );
};

export const DonatePage = () => {
    const [loading, setLoading] = useState(false);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <PageWrapper>
            <Box sx={{ width: "50%", maxWidth: 300 }}>
                <img src={giveIcon} alt="" style={{ width: "100%", height: "100%" }} />
            </Box>
            {
                <DonationSection
                    setLoading={setLoading}
                    redirectUrl={routes.memberDonationSuccess()}
                />
            }
        </PageWrapper>
    );
};
