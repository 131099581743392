import _ from "lodash";
import { matchPath, useLocation } from "react-router";
import { routes } from "../../app/routes";
import { AppFooter } from "./AppFooter";

const PAGES_WITHOUT_FOOTER = [
    routes.login(),
    routes.signup(),
    { path: routes.home(), exact: true },
    routes.teacherPostSession(":sanghaId"),
    routes.memberSanghaDetails(":sanghaId"),
    routes.memberSanghas(),
    routes.memberTeachersPage(),
    routes.memberHomePage(),
    routes.memberSchedulePage(),
    { path: routes.memberZone(), exact: true },
    routes.cancelMembership(),
    routes.memberDonate(),
    routes.memberContribute(),
    routes.addMentorshipGroup(":sanghaId"),
    routes.memberConfirmInterestGroup(":groupId"),
    routes.testInterestGroupGuidelines(),
    routes.conflictResolution(),
    routes.setupFlow(),
    routes.editProfilePage(),
    routes.communityGuidelines(),
    routes.contactUs(),
    routes.memberMembership(),
    routes.allInterestGroups(),
    routes.memberAddInterestGroup(":groupId"),
    routes.addInterestGroup(":groupId"),
    routes.memberUpdatePaymentMethod(),
    routes.teacherZoomAppTestPage(),
    routes.oauthAuthorize(),
    routes.courseSignup(":courseId"),
    routes.coursesDashboard(),
    routes.askATeacher(),
    routes.coursesPublicDashboard(),
    routes.memberSessions(),
    routes.intentionsSubmittedPage(),
    routes.teacherPage(":teacherId"),
    routes.lounge(),
    routes.intentionDetailPage(),
    routes.publicMentorshipGroups(),
    routes.publicAddMentorshipGroup(":sanghaId"),
];

const PAGES_WITHOUT_NEWSLETTER: string[] = [];

const skipFooter = (path: string): boolean => {
    return _.some(PAGES_WITHOUT_FOOTER, (route) => matchPath(path, route));
};

const skipNewsletter = (path: string): boolean => {
    return _.some(PAGES_WITHOUT_NEWSLETTER, (route) => matchPath(path, route));
};

export const AppFooterLoader = () => {
    const location = useLocation();

    if (skipFooter(location.pathname)) {
        return null;
    }

    return <AppFooter hideNewsletter={skipNewsletter(location.pathname)} />;
};
