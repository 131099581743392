import { MembershipBillingChoice } from "@app/shared/types";
import { useLocation } from "react-router";

const cycles: { [key: string]: string } = {
    explorerMonthly: "month",
    explorerAnnual: "year",
};

// we currently activate free trials for everyone. If they go through the cohort-sponsored or mmt-targeted signup flow, they are treated differently
export const isUserInFreeTrialSession = () => {
    return (
        // (localStorage.getItem("isFreeTrial") === "true" ||
        //     localStorage.getItem("signupFlow") === "freetrial") &&
        (!getCurrentCohort())
    );
};

export const isUserInMentorshipSignupFlow = (currentLocation: LocationType) => {
    return currentLocation.pathname.startsWith("/mentorship-groups");
};

export const getCurrentCohort = (): string | null => {
    // Closing Joyous signups: https://app.asana.com/0/1206463723997648/1206041429634182/f
    // return localStorage.getItem("cohort");
    return null;
};

type LocationType = ReturnType<typeof useLocation>;

export const isUserOutsideOfMemberZone = (location: LocationType) => {
    return !location.pathname.startsWith("/member");
};

export const getSignupAnalyticsMetadataFromPlanName = (planName: string | undefined) => {
    return {
        planName,
        cycle: planName ? cycles[planName] : undefined,
        membershipType: "explorer",
        isFreeTrial: isUserInFreeTrialSession(),
        cohort: getCurrentCohort(),
    };
};

export const getSignupAnalyticsMetadataFromPlan = (billingChoice: MembershipBillingChoice) => {
    return {
        ...billingChoice.plan,
        donationAmount: billingChoice.donationAmount,
        isFreeTrial: isUserInFreeTrialSession(),
        cohort: getCurrentCohort(),
    };
};
