import { INTAKE_SURVEY_RELEASE_DATE } from "@app/shared/constants";
import { Sangha, SanghaMembership, SanghaType } from "@app/shared/types";
import { isRunningAutomatedTest } from "app/config";
import { DateTime } from "luxon";
import { ParsedQuery } from "query-string";

export const shouldShowIntakeSurvey = (
    sangha: Sangha,
    query: ParsedQuery<string>,
    sanghaMembership?: SanghaMembership | null,
    userIsTeacherOfEvent?: boolean,
) => {
    const isMentorshipGroup = sangha.type === SanghaType.MentorshipGroup;
    const hasJoinedGroupAfterIntakeSurveyRelease =
        sanghaMembership &&
        DateTime.fromISO(sanghaMembership?.startDate) > INTAKE_SURVEY_RELEASE_DATE;
    const hasCompletedIntakeSurvey = !!sanghaMembership?.hasCompletedIntakeSurvey;
    const isRunningInAutomatedTest = isRunningAutomatedTest() || query.skip_survey === "true"; // skip_survey is a temporary solution because isRunningPlaywright() is not working
    return (
        isMentorshipGroup &&
        !userIsTeacherOfEvent &&
        hasJoinedGroupAfterIntakeSurveyRelease &&
        !hasCompletedIntakeSurvey &&
        !isRunningInAutomatedTest
    );
};

export const shouldShowFirstThingsFirstSection = (
    mentorshipGroupSanghaMembership: SanghaMembership,
) => {
    const userHasActiveMentorshipGroup = !!mentorshipGroupSanghaMembership;
    const userHasCompletedIntakeSurvey =
        !!mentorshipGroupSanghaMembership?.hasCompletedIntakeSurvey;
    const hasJoinedGroupAfterIntakeSurveyRelease =
        DateTime.fromISO(mentorshipGroupSanghaMembership?.startDate) > INTAKE_SURVEY_RELEASE_DATE;
    const showFirstThingsFirstSection =
        userHasActiveMentorshipGroup &&
        !userHasCompletedIntakeSurvey &&
        hasJoinedGroupAfterIntakeSurveyRelease;
    return showFirstThingsFirstSection;
};

export const getWelcomePopupContent = (mentorshipGroupSignupFlow: string | null) => {
    let popupHeader, popupBody;

    if (mentorshipGroupSignupFlow === "member") {
        popupHeader = "You Now Have A Mentorship Group 🎉";
        popupBody =
            "We've added you to your chosen mentorship group. Please complete your intentions so your teacher can prepare to meet your needs.";
    } else if (mentorshipGroupSignupFlow === "non-member") {
        popupHeader = "Welcome to Banyan 🎉";
        popupBody =
            "You're officially part of a small mentorship group and our greater Banyan community! Help your mentor get to know you by sharing your intentions with them.";
    }

    return { popupHeader, popupBody };
};
