import { ReactTyped } from "react-typed";

export const TypedQuestions = () => {
    return (
        <ReactTyped
            strings={[
                "What is something you're reflecting on?",
                "What question is most alive for you?",
                "What are you struggling with right now?",
                "What is a moment that challenged you?",
                "What are you most curious about these days?",
                "What's causing you distress?",
                "Where do you have room to grow?",
                "What could you use support with?",
                "Where are you stuck?",
                "What's your primary need right now?",
                "What are you longing to experience?",
                "What's breaking your heart?",
            ]}
            typeSpeed={25}
            backSpeed={15}
            backDelay={5000}
            loop
        />
    );
};
