import { BRAND_NAME, CONFLICT_RESOLUTION_EMAIL, CONTACT_EMAIL } from "@app/shared/constants";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "features/navigation/NavLink";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    subList: {
        listStyleType: "lower-alpha",
    },
}));

export const Guidelines = ({ mainHeadingVariant = "h2" }: { mainHeadingVariant?: "h1" | "h2" }) => {
    const classes = useStyles();

    const exampleQuotes = [
        `“I feel deeply triggered about Trump being re-elected. It seems surreal. I know I'm not the only one who feels this way but lying awake at night I feel so alone in my fears about the future. I feel so angry because I care about all people having equal rights and I need to know my transgender child can be safe and loved in our own suburb, nevermind in our country.”`,
        `“I feel alone right now. I live in Poland and I am worried about the impact of immigration on our economy so I voted for a right-wing political party even though I don't agree with everything they say. I'm just struggling so much financially and I'm worried it will get worse. I feel wronged for my choice. I feel guilty that I disappointed my friends, and I also notice I'm getting angry and defensive. I'm unsure how I can continue to open my heart to people and also honor my truth.”`,
        `“I feel very scared for the earth and the future of my children. Last week I learned how much of the coral reef has already died because the oceans are heating. The situation seems so urgent and I have a story that many people don't really care. I just feel so powerless and scared and angry about it. How can I find peace knowing that we are harming our planet?”`,
        `“I have family in Gaza. Last night's report on the Nuseirat bombing triggered deep terror in me. I feel utterly powerless. Hearing about civilian suffering makes me want to scream, shut down, and lash out. I don't know how to hold all this emotion and I don't believe I have it in me to be compassionate for everyone involved in the war. I just want my family to live a safe, peaceful life. I'm distraught. I can't understand why this is happening!”`,
    ];

    return (
        <div className={classes.contentContainer}>
            <Typography variant="h1">Community Guidelines</Typography>
            <Typography variant="body1">
                These guidelines are the foundation of our respectful, supportive, and inclusive
                community. They are our collective commitment to creating a space where everyone
                feels safe to share, listen, seek understanding, and grow in mindfulness and
                compassion. <b>Please give them your care and attention.</b>
            </Typography>
            <Typography variant="h3">Our values</Typography>
            <Typography variant="body1">
                {BRAND_NAME} is a place to connect with our shared humanity. Our guidelines promote
                these primary values and behaviors:
            </Typography>
            <Typography variant="body1" component="div">
                <ol>
                    <li>
                        <b>Cultivating compassion </b> for ourselves and all beings.
                    </li>
                    <li>
                        <b>Seeking understanding </b> of ourselves and all beings.
                    </li>
                    <li>
                        <b>Revealing our inter-connectedness </b> through exploring collective
                        needs, joys, and struggles.
                    </li>
                </ol>
            </Typography>
            <Typography variant="h3">{BRAND_NAME} is a safe space and brave space</Typography>
            <Typography variant="body1">
                We commit to maintaining a safe space where everyone feels welcome to practice
                sharing their life experiences, emotions, and thoughts through the lens of
                mindfulness and compassion. We listen deeply and speak with regard for self and
                others. We authentically share the joys and challenges that arise in life.
            </Typography>
            <Typography variant="body1">
                We are here for common reasons. We help each other to see reality as it truly is,
                and to break cycles of greed, fear, hatred, and delusion. We cultivate wisdom and
                compassion to liberate ourselves and others from suffering.
            </Typography>
            <Typography variant="body1">
                At {BRAND_NAME} our core practices are mindfulness, creating connection, and
                building resilience. We steady our hearts and cultivate insight, so that we can act
                wisely in the world. We do not avoid difficulty. Rather, amidst difficulty we turn
                with kindness towards our shared humanity so that we can act in the world with
                understanding and compassion.
            </Typography>
            <Typography variant="h3">I agree to&hellip;</Typography>
            <Typography variant="body1">
                As a member of {BRAND_NAME}, I commit to upholding these guidelines in their
                entirety. These principles apply to every aspect of the {BRAND_NAME} community,
                including Mindful Friend Groups (MFGs), Mentorship Groups, retreats, courses,
                sessions, breakout rooms, community talks, Q+As, the Lounge, and any personal
                interactions. By agreeing to the following, I contribute to creating a safe,
                inclusive, and supportive space for all members:
            </Typography>
            <Typography variant="body1" component="div">
                <ol>
                    <li>
                        <b>Honor everyone's unique journey</b> by embracing diversity in race,
                        color, nationality, sex, sexual orientation, gender, age, religion, body
                        size, ability, medical conditions, military status, or marital status. Be
                        mindful that each person's experience is their own, and we're here to
                        support one another with openness and respect.
                    </li>
                    <li>
                        <b>Support others with kindness and respect.</b> Approach every interaction
                        with a spirit of compassion and understanding, creating a space where
                        everyone feels safe to share.
                    </li>
                    <li>
                        <b>Share from my own experience</b>, using “I” statements. Speak honestly
                        and vulnerably from the heart, without assuming or projecting onto others'
                        experiences.
                    </li>
                    <li>
                        <b>Take responsibility</b> for my own process when I share. Allow others to
                        be a loving witness, but don't expect them to hold my emotional weight. If I
                        need to pause, take a breath, or step away, I can do so without
                        judgment—trusting the space will still be there when I return.
                    </li>
                    <li>
                        <b>Avoid giving unsolicited advice</b> or trying to 'fix' others. Offer
                        support by simply listening and being present, allowing people to find their
                        own path.
                    </li>
                    <li>
                        <b>Hold what others share in strict confidence.</b> Stories shared in this
                        space are personal and should be honored with care and privacy.
                    </li>
                    <li>
                        <b>Avoid using AI transcription tools</b> such as read.ai, otter.ai, and
                        Zoom IQ. Ensure that all voices are heard in their true, authentic form
                        within this space.
                    </li>
                    <li>
                        <b>Encourage full participation</b> by all present. Be mindful of how often
                        you speak—sit back to create space for others if you tend to contribute
                        frequently, and speak up if you usually stay quiet. The goal is to create a
                        balanced, inclusive environment where everyone feels they can share.
                    </li>
                    <li>
                        <b>Set aside roles</b> like coach, teacher, or therapist during community
                        conversations. Show up as a fellow participant, allowing everyone the space
                        to express without authority or judgment.
                    </li>
                    <li>
                        <b>Avoid promoting products or services</b> or asking for personal favors
                        outside the group context. Focus on genuine connection and shared
                        experiences, free from external agendas.
                    </li>
                </ol>
            </Typography>
            <Typography variant="h3">How to Practice with Charged Current Events</Typography>
            <Typography variant="body1">
                {BRAND_NAME}, is a space to deepen our understanding of ourselves and others. We are
                here to practice with life circumstances, not to debate them. {BRAND_NAME} is not a
                space for advocacy and debate on global events, conflict, public policy, etc. Other
                important spaces exist to meet those needs of expression. Here, we want to connect
                with our shared humanity, so that we can engage wisely with the wider world.
            </Typography>
            <Typography variant="body1">
                How you communicate matters. While mindful inquiry creates understanding and
                connection, reactivity can cause harm and further division. This is why we ask you
                to share your inner experience in a way that builds connection.
            </Typography>
            <Typography variant="body1">
                It's an advanced practice to work with subjects like:
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>Global events, wars, and conflicts.</li>
                    <li>Social justice and activism.</li>
                    <li>Public health issues (e.g. vaccinations).</li>
                    <li>Economic inequality and class struggles.</li>
                    <li>Environmental issues (e.g., climate change.</li>
                </ul>
            </Typography>
            <Typography variant="body1">We ask that you practice:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Taking responsibility for yourself. </b> You're responsible for your
                        emotional sobriety and how you communicate what is present. Please share in
                        a manner that engages constructive communication and reflects mindfulness
                        teachings, to avoid causing harm, othering, and evangelizing.
                    </li>
                    <li>
                        <b>Letting go of sharing or debating political opinions. </b> It's ok to
                        name a trigger and its impact, but this is not the place to debate policy.
                    </li>
                    <li>
                        <b>Focusing on how mindfulness can help you hold the difficulties </b> that
                        arise from large triggers rather than engaging in reactive sharing or
                        emotional expression.
                    </li>
                    <li>
                        <b>
                            Seeking to understand the collective suffering happening in our world.{" "}
                        </b>
                        We all have common biases and veils that we have the capacity to recognize,
                        practice with, and evolve beyond.
                    </li>
                    <li>
                        <b>Letting go of </b> pointing fingers, making other people wrong, silencing
                        other people, judging and criticizing others, or “othering” a person, group,
                        or place.
                    </li>
                    <li>
                        <b>Communicating mindfully, </b> by:
                        <ol>
                            <li>
                                Actively engaging self-regulation practices (mindful breathing,
                                movement, journaling).
                            </li>
                            <li>
                                Naming a trigger and its impacts with the sincere intention to
                                create more understanding and re-establish connection with yourself
                                and others.
                            </li>
                            <li>
                                Turn towards curiosity and inquiry. For example, asking yourself:
                                “What is it that I care about in this situation?”, “What is it that
                                matters most to me in this situation?”, “What is my primary unmet
                                need?”, “What is my intention in speaking?”
                            </li>
                            <li>
                                Share with the community from a centered, grounded place (to the
                                best of your ability).
                            </li>
                        </ol>
                    </li>
                    <li>
                        <b>Cultivating compassion </b> for yourself and others when we fall short of
                        upholding these practices as we meet challenging experiences.
                    </li>
                </ul>
            </Typography>
            <Typography variant="h4">Examples</Typography>
            {exampleQuotes.map((quote, index) => (
                <Box
                    key={index}
                    component="blockquote"
                    sx={{
                        borderLeft: (theme) => `4px solid ${theme.palette.divider}`,
                        ml: 0,
                        pl: 2,
                        color: "text.secondary",
                        fontStyle: "italic",
                        "&:not(:last-child)": {
                            mb: 4,
                        },
                    }}
                >
                    <Typography variant="body1" component="p">
                        {quote}
                    </Typography>
                </Box>
            ))}
            <Typography variant="body1">
                These examples clearly name the triggering events and their impact. They do not
                engage in debate. They focus on inner experience and needs.
            </Typography>
            <Typography variant="body1">
                We are all human and we don't expect “perfect shares”. They don't exist. We do
                expect you to follow our guidelines to the best of your ability so that we can honor
                this beautiful community together.
            </Typography>
            <Typography variant="h3">During live sessions:</Typography>
            <Typography variant="body1">
                <ul>
                    <li>
                        <b>Arrive early</b> to settle in for an on-time start.
                    </li>
                    <li>
                        <b>Mute my microphone</b> when not speaking.
                    </li>
                    <li>
                        <b>Use a device with a big screen</b> that allows me to see everyone.
                    </li>
                    <li>
                        <b>Turn off all other devices</b> (cellphones, tablets, etc.) to give my
                        full presence to the session and refrain from multitasking.
                    </li>
                    <li>
                        <b>Keep my video on</b> to enhance connection, and briefly switch it off if
                        I need to move.
                    </li>
                    <li>
                        <b>"Hide Self View" in Zoom</b> for more natural interaction.
                    </li>
                </ul>
            </Typography>
            <Typography variant="h3">In breakout rooms:</Typography>
            <Typography variant="body1">
                <ul>
                    <li>
                        <b>Nominate a timekeeper.</b>
                    </li>
                    <li>
                        <b>Know that it's okay not to share.</b>
                    </li>
                    <li>
                        <b>Chat casually</b> once everyone has had a chance to respond to the
                        prompt.
                    </li>
                    <li>
                        <b>Leave the breakout room</b> or session anytime I feel uncomfortable.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">Dealing with trauma & emotional triggers:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        We recognize that some members may be processing trauma or experiencing
                        heightened emotional responses. While we offer a supportive space, please
                        note that {BRAND_NAME} is not the appropriate setting for
                        <b> active trauma processing.</b> If you feel that sharing your trauma
                        exceeds what can be supported here, we encourage you to seek additional
                        support from a therapist or professional.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                What to do when impacted by another member's share:
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Pause. Take a moment to breathe and care for yourself.</b>
                    </li>
                    <li>
                        Consider that there may have been no intent to cause harm or awareness that
                        harm was caused.
                    </li>
                    <li>
                        <b>Without judgment or blame</b>, let your group know that something shared
                        impacted you, using “I” statements. This can lead to deeper understanding
                        and healing for all.
                    </li>
                    <li>
                        If the current session feels too charged,{" "}
                        <b>wait until the next session </b>
                        to share.
                    </li>
                    <li>
                        If you are outside your window of tolerance for what feels safe, please take
                        steps to gently care for yourself.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                What to do when concerned you have impacted another member:
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Pause, relax, and open</b>; take a moment to breathe and extend
                        self-compassion.
                    </li>
                    <li>
                        Acknowledge that you may have caused unintentional harm. Example: “If what I
                        shared caused harm, please forgive me. This was not my intention, and I
                        understand there may still have been impact.”
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">Report concerns or conflicts:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        If at any time you feel uncomfortable or concerned about another member's
                        share, please report it to {BRAND_NAME}{" "}
                        <NavLink href={`mailto:${CONFLICT_RESOLUTION_EMAIL}`}>
                            ({CONFLICT_RESOLUTION_EMAIL})
                        </NavLink>
                        .
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                I understand that {BRAND_NAME} reserves the right to&hellip;
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Redirect members to seek professional support</b> from a therapist or
                        other expert if participation here could be detrimental.
                    </li>
                    <li>
                        <b>Remove members who repeatedly breach these guidelines.</b>
                    </li>
                    <li>
                        <b>Remove posts that are divisive or exclusionary.</b>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">The Dos & Don'ts of mindful communication:</Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <b>Do</b> express your emotions in a way that fosters understanding and
                        connection.
                    </li>
                    <li>
                        <b>Do</b> reflect on how your words may impact others before sharing.
                    </li>
                    <li>
                        <b>Don't</b> engage in political debates or advocacy. Instead, focus on how
                        mindfulness can help you navigate difficult emotions.
                    </li>
                    <li>
                        <b>Do</b> practice compassion and respect, even when dealing with
                        challenging topics.
                    </li>
                    <li>
                        <b>Don't</b> expect others to hold your emotions—everyone is here to witness
                        and support, but your process is your responsibility.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h3">
                Thank You for Being Part of the {BRAND_NAME} Community
            </Typography>
            <Typography variant="body1">
                We deeply appreciate your commitment to fostering a mindful, compassionate, and
                inclusive space at {BRAND_NAME}. By following these guidelines, you help create a
                safe environment where everyone can feel heard, supported, and respected. Together,
                we are building a community rooted in mindfulness, personal growth, and shared
                connection.
            </Typography>
            <Typography variant="body1">
                Thank you for your presence, participation, and care. We are here when you need us.
                Email us at <NavLink href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</NavLink> if
                you have any questions or need clarification on any of our Community Guidelines.
            </Typography>
            <Typography variant="body1">
                In Service,
                <br />
                The {BRAND_NAME} Team
            </Typography>
        </div>
    );
};

export default Guidelines;
