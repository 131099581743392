// This error page is rendered without any dependencies on the rest of the app
export const RawGenericErrorPage = () => (
    // TODO - how to add logging without dependencies????
    // add logging here?
    (<div style={{ padding: "1em" }}>
        <p style={{ fontFamily: "sans-serif", textAlign: "center" }}>
            Whoops. Something went wrong on our end. Please try again or{" "}
            <a href="/contact">get in touch with us</a>.
        </p>
    </div>)
);
