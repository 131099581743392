import makeStyles from "@mui/styles/makeStyles";
import { Link, LinkProps } from "@mui/material";
import { push } from "connected-react-router";
import { MouseEvent } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import classNames from "classnames";

interface NavLinkProps extends LinkProps {
    to?: string;
    redirectState?: { from?: string; params?: string };
    additionalOnClick?: (ev: React.MouseEvent<any>) => void;
    children: React.ReactNode;
    stopPropagation?: boolean;
    disabled?: boolean;
    target?: React.HTMLAttributeAnchorTarget | undefined;
    openInNewTab?: boolean;
    disableLinkStyles?: boolean;
}

const useStyles = makeStyles((theme) => ({
    disabled: {
        pointerEvents: "none",
        cursor: "default",
        color: theme.palette.grey300,
    },
    disableLinkStyles: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
            textDecoration: "none",
            color: "inherit",
        },
    },
}));

export const NavLink = (props: NavLinkProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
        to,
        redirectState,
        additionalOnClick = () => null,
        children,
        stopPropagation,
        disabled = false,
        target,
        openInNewTab,
        disableLinkStyles,
        ...others
    } = props;

    const onClick = (ev: MouseEvent) => {
        if (!to) {
            return;
        }

        ev.preventDefault();
        if (to.startsWith("http") && to.includes("://")) {
            if (openInNewTab) {
                window.open(to, "_blank", "noopener,noreferrer");
            } else {
                window.location.href = to;
            }
        } else {
            dispatch(push(to, redirectState));
        }

        if (stopPropagation) {
            ev.stopPropagation();
        }

        additionalOnClick(ev);
    };

    return (
        <Link
            className={classNames({
                [classes.disabled]: disabled,
                [classes.disableLinkStyles]: disableLinkStyles,
            })}
            href={to}
            onClick={onClick}
            target={target}
            children={children}
            {...others}
        />
    );
};
