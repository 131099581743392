import { useQuery } from "@apollo/client";
import { Sangha, SanghaType } from "@app/shared/types";
import { Box, Typography } from "@mui/material";
import { GRAPHQL_QUERY_MY_SANGHAS } from "app/queries";
import { routes } from "app/routes";
import { theme } from "app/theme";
import PageWrapper from "components/PageWrapper";
import { LinkButton } from "features/navigation/LinkButton";

export const FirstThingsFirstSection = () => {
    const { data, error } = useQuery(GRAPHQL_QUERY_MY_SANGHAS, {
        fetchPolicy: "network-only",
        variables: { includePending: true },
    });

    const sanghas = data?.mySanghas as Sangha[];
    const mentorshipGroup = sanghas?.find((sangha) => sangha.type === SanghaType.MentorshipGroup);

    if (error || !mentorshipGroup) {
        return null;
    }

    return (
        <Box sx={{ backgroundColor: theme.palette.neutralWarm }}>
            <PageWrapper smallPaddingTop>
                <Typography variant="h2" sx={{ mt: 2 }}>
                    First things first
                </Typography>
                <Typography variant="h3" sx={{ mb: 0 }}>
                    Let's get you into your group
                </Typography>
                <Typography variant="body1">
                    Share your intentions with your mentor before your first session
                </Typography>
                <LinkButton variant="primary" to={routes.memberSanghaDetails(mentorshipGroup.id)}>
                    Get started
                </LinkButton>
            </PageWrapper>
        </Box>
    );
};
