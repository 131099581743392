import { useMutation } from "@apollo/client";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { GRAPHQL_MUTATION_CREATE_POST_ON_CIRCLE } from "app/queries";
import PageWrapper from "components/PageWrapper";
import { useContext, useRef, useState } from "react";
import { TypedQuestions } from "./TypedQuestions";
import { theme } from "app/theme";
import { NavLink } from "features/navigation/NavLink";
import { routes } from "app/routes";
import { LinkButton } from "features/navigation/LinkButton";
import { analyticsTrack } from "app/analytics/track";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { CSDialog } from "components/CSDialog";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";

enum PostVisibility {
    Public = "public",
    Anonymous = "anonymous",
}

const QUESTION_SUGGESTIONS = [
    "What can I do to stay focused during meditation?",
    "I'm struggling to accept what's happening in the world - any advice?",
    "How do I deepen my meditation practice?",
    "How do I bring mindfulness into my daily routine?",
    "How do I know if I'm making progress on the mindfulness path?",
    "How do I integrate practicing mindfulness with my activism?",
    "What is the difference between letting go and suppressing my experience or emotions?",
    "Can mindfulness help me think more clearly and make better decisions?",
];

export const AskATeacherPage = () => {
    const user = useContext(LoggedInUserProfileContext);

    const [postVisibility, setPostVisibility] = useState<PostVisibility>(PostVisibility.Public);
    const [question, setQuestion] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [postURL, setPostURL] = useState<string | null>(null);
    const [urlError, setUrlError] = useState<boolean>(false);
    const [previewPopupOpen, setPreviewPopupOpen] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const startedTyping = useRef<boolean>(false);

    const isPostingAnonymous = postVisibility === PostVisibility.Anonymous;

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [createPostOnCircleMutation, { loading: isSending, error: circlePostError }] =
        useMutation(GRAPHQL_MUTATION_CREATE_POST_ON_CIRCLE, {
            variables: {
                content: question,
                anonymous: postVisibility === PostVisibility.Anonymous,
            },
        });

    const handlePostVisibilityChange = (
        event: React.MouseEvent<HTMLElement>,
        newPostVisibility: PostVisibility | null,
    ) => {
        if (newPostVisibility !== null) {
            setPostVisibility(newPostVisibility);
        }
    };

    const handleTextFieldTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(event.target.value);
        if (!startedTyping.current) {
            analyticsTrack("question.typing.started");
            startedTyping.current = true;
        }
    };

    const onSubmit = async () => {
        analyticsTrack("post.question.clicked", { question });
        setPreviewPopupOpen(false);
        finalQuestion.current = question;
        const result = await createPostOnCircleMutation();
        const postURL = result.data.createPostOnCircle;
        if (!postURL) {
            setUrlError(true);
            return;
        }
        setPostURL(postURL);
        setSnackbarOpen(true);
        setQuestion("");
    };

    const handlePreviewButtonClick = () => {
        analyticsTrack("question.preview.clicked");
        setPreviewPopupOpen(true);
    };

    const handleKeyDown = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && !!question) {
            handlePreviewButtonClick();
        }
    };

    const finalQuestion = useRef<string | null>(null);

    return (
        <PageWrapper>
            <Box
                sx={{
                    textAlign: "center",
                    maxWidth: 1000,
                    margin: "0 auto",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.grey100,
                        p: 2,
                        borderRadius: 0.5,
                        mb: { xs: 2, md: 4 },
                    }}
                >
                    <Typography variant="h2" sx={{ mb: 0 }}>
                        <TypedQuestions />
                    </Typography>
                    <Typography variant="body1">
                        Your question will be posted on{" "}
                        <NavLink to={routes.lounge()} openInNewTab>
                            The Lounge
                        </NavLink>{" "}
                        to be answered by a teacher. You can post as yourself, or anonymously.
                    </Typography>
                    <ToggleButtonGroup
                        exclusive
                        value={postVisibility}
                        onChange={handlePostVisibilityChange}
                        color="secondary"
                        sx={{ mb: 1 }}
                    >
                        <ToggleButton
                            value={PostVisibility.Public}
                            sx={{ display: "flex", gap: { xs: 0.5, md: 1 }, alignItems: "center" }}
                        >
                            <span className="material-symbols-rounded">visibility</span>Public Post
                        </ToggleButton>
                        <ToggleButton
                            value={PostVisibility.Anonymous}
                            sx={{ display: "flex", gap: { xs: 0.5, md: 1 }, alignItems: "center" }}
                        >
                            <span className="material-symbols-rounded">comedy_mask</span>
                            Anonymous Post
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <TextField
                    value={question}
                    placeholder="Ask our teachers a question"
                    fullWidth
                    autoFocus={!isMobile}
                    onChange={handleTextFieldTyping}
                    onKeyDown={handleKeyDown}
                    multiline={isMobile}
                    rows={3}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 0.5,
                            mb: { xs: 1, sm: 2 },
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ mr: 2 }}>
                                <span className="material-symbols-rounded">help</span>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {!isMobile &&
                                    (question ? (
                                        isSending ? (
                                            <CircularProgress size={30} />
                                        ) : (
                                            <Button
                                                variant="primary"
                                                size="small"
                                                sx={{ p: 0.5, pr: 0 }}
                                                onClick={handlePreviewButtonClick}
                                            >
                                                Preview{" "}
                                                <span
                                                    className="material-symbols-rounded"
                                                    style={{
                                                        fontSize: theme.typography.pxToRem(30),
                                                    }}
                                                >
                                                    chevron_right
                                                </span>
                                            </Button>
                                        )
                                    ) : (
                                        <IconButton
                                            color="secondary"
                                            size="small"
                                            sx={{ p: 0.5 }}
                                            disabled={!question}
                                        >
                                            <span
                                                className="material-symbols-rounded"
                                                style={{ fontSize: theme.typography.pxToRem(30) }}
                                            >
                                                chevron_right
                                            </span>
                                        </IconButton>
                                    ))}
                            </InputAdornment>
                        ),
                    }}
                />
                {isMobile &&
                    (isSending ? (
                        <CircularProgress size={30} />
                    ) : (
                        <Button
                            variant="primary"
                            size="small"
                            sx={{ p: 0.5, pr: 0, mb: 2 }}
                            fullWidth
                            disabled={!question}
                            onClick={handlePreviewButtonClick}
                        >
                            Preview{" "}
                            <span
                                className="material-symbols-rounded"
                                style={{
                                    fontSize: theme.typography.pxToRem(30),
                                }}
                            >
                                chevron_right
                            </span>
                        </Button>
                    ))}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {QUESTION_SUGGESTIONS.map((suggestion, index) => {
                        if (!showMoreOptions && index >= 4) {
                            return null;
                        }

                        return (
                            <Button
                                key={suggestion}
                                variant="optionBox"
                                sx={{ pointerEvents: "none", userSelect: "text" }}
                            >
                                {suggestion}
                            </Button>
                        );
                    })}

                    {!showMoreOptions && QUESTION_SUGGESTIONS.length > 4 && (
                        <Button
                            variant="optionBox"
                            onClick={() => setShowMoreOptions(true)}
                            sx={{ px: 1.5, minWidth: 0 }}
                        >
                            More
                        </Button>
                    )}
                </Box>
                {(circlePostError || urlError) && (
                    <Typography variant="body1" color="error" sx={{ mt: 4 }}>
                        <GenericErrorMessage
                            alternateInstruction="An error occured while posting your question. Please reload the page and try
                        again. If that doesn't work, please"
                        />
                    </Typography>
                )}
            </Box>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setSnackbarOpen(false)}
                message="Your question was posted on the Lounge 🎉"
                color="secondary"
                sx={{
                    "& .MuiSnackbarContent-root": {
                        backgroundColor: theme.palette.success500,
                        py: 0.5,
                        fontSize: theme.typography.pxToRem(18),
                        px: 3,
                        textAlign: { xs: "center", lg: "left" },
                    },
                    "& .MuiSnackbarContent-action": {
                        margin: { xs: "0 auto", lg: "0" },
                    },
                    "& .MuiSnackbarContent-message": {
                        width: { xs: "100%", lg: "auto" },
                    },
                }}
                action={
                    <>
                        <LinkButton variant="primary" size="small" to={postURL || ""} openInNewTab>
                            View post
                        </LinkButton>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSnackbarOpen(false)}
                            sx={{ ml: 1 }}
                        >
                            <span className="material-symbols-rounded">close</span>
                        </IconButton>
                    </>
                }
            />
            <CSDialog
                open={previewPopupOpen}
                onClose={() => setPreviewPopupOpen(false)}
                sx={{ maxWidth: 800, margin: "0 auto" }}
            >
                <Typography variant="h3" sx={{ mb: 0 }}>
                    This is how your post will look in The Lounge
                </Typography>
                <Typography variant="body1">
                    {isPostingAnonymous
                        ? "Your question will be automatically posted in The Lounge. Because your post is anonymous you will not be notified when a teacher answers you. Please check the replies of your post manually."
                        : "Your question will be automatically posted in The Lounge and you'll be notified when a teacher has answered you."}
                </Typography>
                <Box
                    sx={{
                        backgroundColor: theme.palette.neutralGold,
                        borderRadius: `${theme.borderRadius.default}px`,
                        p: 2,
                        mt: 2,
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
                        <Avatar
                            src={isPostingAnonymous ? undefined : user.profile.picture || undefined}
                            sx={{ width: 18, height: 18 }}
                        />
                        <Typography variant="caption" sx={{ mb: 0 }}>
                            By {isPostingAnonymous ? "Anonymous" : `${user.profile.fullName}`}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                        {question}
                    </Typography>
                    <Typography variant="caption" sx={{ color: theme.palette.grey700 }}>
                        Draft
                    </Typography>
                </Box>
                {question === finalQuestion.current && (
                    <Typography
                        variant="caption"
                        sx={{ color: theme.palette.warning500, display: "block", mt: 1 }}
                    >
                        You already posted this question. Please edit your question so you can post
                        again.
                    </Typography>
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                        gap: 2,
                        flexDirection: {
                            xs: "column",
                            sm: "row",
                        },
                    }}
                >
                    <Button
                        variant="secondary"
                        onClick={() => {
                            analyticsTrack("question.preview.edit.clicked");
                            setPreviewPopupOpen(false);
                        }}
                        fullWidth={isMobile}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onSubmit}
                        fullWidth={isMobile}
                        disabled={question === finalQuestion.current || isSending}
                    >
                        Post question
                    </Button>
                </Box>
            </CSDialog>
        </PageWrapper>
    );
};
